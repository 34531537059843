/**
 * @fuegokit/tokens 0.26.0
 * Do not edit directly
 * Generated on Thu, 03 Apr 2025 22:21:01 GMT
 */
export default {
  radii: {
    "0": "0px",
    "1": "2px",
    "2": "4px",
    "3": "6px",
    "4": "8px",
    "5": "99999px",
    none: "0px",
    xsmall: "1px",
    small: "2px",
    medium: "4px",
    large: "8px",
    xlarge: "12px",
    round: "99999px",
  },
};
