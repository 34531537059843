/**
 * @fuegokit/tokens 0.26.0
 * Do not edit directly
 * Generated on Thu, 03 Apr 2025 22:21:00 GMT
 */
export default {
  fontFamily: {
    sansSerif: '"SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
    monospace: '"SF Mono", ui-monospace, Menlo, "Segoe UI Mono", "Ubuntu Mono", monospace',
    heading: '"SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
    display: '"SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
    body: '"SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
    normal: '"SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
    mono: '"SF Mono", ui-monospace, Menlo, "Segoe UI Mono", "Ubuntu Mono", monospace',
  },
  fontFamilies: {
    sansSerif: '"SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
    monospace: '"SF Mono", ui-monospace, Menlo, "Segoe UI Mono", "Ubuntu Mono", monospace',
    title: '"SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
    body: '"SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
    normal: '"SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
    mono: '"SF Mono", ui-monospace, Menlo, "Segoe UI Mono", "Ubuntu Mono", monospace',
  },
  fontStack: {
    sansSerif: 'ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
    monospace: 'ui-monospace, Menlo, "Segoe UI Mono", "Ubuntu Mono", monospace',
    title: '"SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
    body: '"SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
    normal: '"SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
    mono: '"SF Mono", ui-monospace, Menlo, "Segoe UI Mono", "Ubuntu Mono", monospace',
  },
  fontWeight: {
    thin: 100,
    extraLight: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semiBold: 600,
    semibold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
  fontWeights: {
    extraLight: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extraBold: 700,
    black: 700,
  },
  fontSize: {
    "0": "10px",
    "1": "11px",
    "2": "12px",
    "3": "14px",
    "4": "16px",
    "5": "20px",
    "6": "24px",
    "7": "28px",
    "8": "29px",
    "9": "32px",
    "10": "35px",
    "11": "36px",
    "12": "48px",
    "13": "56px",
  },
  fontSizes: {
    "0": "10px",
    "1": "11px",
    "2": "12px",
    "3": "14px",
    "4": "16px",
    "5": "18px",
    "6": "20px",
    "7": "24px",
    "8": "28px",
    "9": "29px",
    "10": "35px",
    "11": "36px",
    "12": "47px",
    "13": "62px",
  },
  lineHeight: {
    "0": "16px",
    "1": "20px",
    "2": "24px",
    "3": "28px",
    "4": "32px",
    "5": "36px",
    "6": "40px",
    default: 1.429,
    condensed: 1.143,
    condensedUltra: 1,
    relaxed: 1.714,
  },
  lineHeights: {
    "0": "16px",
    "1": "20px",
    "2": "24px",
    "3": "28px",
    "4": "32px",
    "5": "36px",
    "6": "40px",
    default: 1.429,
    condensed: 1.143,
    condensedUltra: 1,
    relaxed: 1.714,
  },
  letterSpacing: {
    "0": "0px",
    "1": "-0.05px",
    "2": "-0.09px",
    "3": "-0.13px",
    "4": "-0.16px",
  },
  font: {
    heading: {
      xxsmall: '700 12px/16px "SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
      xsmall: '700 14px/16px "SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
      small: '700 16px/20px "SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
      medium: '700 20px/24px "SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
      large: '700 24px/28px "SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
      xlarge: '700 28px/32px "SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
      xxlarge: '700 29px/36px "SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
    },
    body: {
      "[default]": '400 14px/20px "SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
      small: '400 12px/20px "SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
      medium: '400 14px/20px "SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
      large: '400 16px/24px "SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
      xlarge: '400 20px/28px "SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
    },
    ui: {
      "[default]": '500 14px "SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
      small: '500 11px "SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
    },
    caption: {
      "[default]": '400 12px/16px "SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
    },
    code: {
      "[default]": '400 14px "SF Mono", ui-monospace, Menlo, "Segoe UI Mono", "Ubuntu Mono", monospace',
      xsmall: '400 12px "SF Mono", ui-monospace, Menlo, "Segoe UI Mono", "Ubuntu Mono", monospace',
      small: '400 14px "SF Mono", ui-monospace, Menlo, "Segoe UI Mono", "Ubuntu Mono", monospace',
      medium: '400 16px "SF Mono", ui-monospace, Menlo, "Segoe UI Mono", "Ubuntu Mono", monospace',
    },
    display: {
      "[default]": '500 56px "SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
      medium: '500 48px "SF Pro", ui-sans-serif, "Segoe UI", system-ui, Ubuntu, "Helvetica Neue", sans-serif',
    },
  },
};
