/**
 * @fuegokit/tokens 0.26.0
 * Do not edit directly
 * Generated on Thu, 03 Apr 2025 22:20:55 GMT
 */
export default {
  scale: {
    white: "#ffffff",
    black: "#00000000",
    gray: {
      "100": "#f8f8f8",
      "200": "#f4f4f4",
      "300": "#f2f2f2",
      "400": "#ebebeb",
      "500": "#dedede",
      "600": "#c8c8c8",
      "700": "#a6a6a6",
      "800": "#666666",
      "900": "#3c3c3c",
      "000": "#ffffff",
    },
    neutral: {
      "100": "#f8f8f8",
      "200": "#f4f4f4",
      "300": "#efefef",
      "400": "#eaeaea",
      "500": "#dadada",
      "600": "#c8c8c8",
      "700": "#a6a6a6",
      "800": "#666666",
      "900": "#4c4c4c",
      "1000": "#333333",
      "1100": "#000000",
      "000": "#ffffff",
    },
    neutralAlpha: {
      "100": "#0000000a",
      "200": "#0000000f",
      "300": "#00000014",
      "400": "#0000001a",
      "500": "#00000033",
      "600": "#0000004d",
      "700": "#00000099",
      "800": "#000000b3",
      "900": "#000000cc",
      "000": "#00000005",
    },
    blue: {
      "100": "#deecf9",
      "200": "#c7e0f4",
      "300": "#2b88d8",
      "400": "#0078d4",
      "500": "#106ebe",
      "600": "#0067b5",
      "700": "#005a9e",
      "800": "#005ba1",
      "900": "#004578",
      "000": "#eff6fc",
    },
    teal: {
      "100": "#caedec",
      "200": "#a3dfe4",
      "300": "#70e2df",
      "400": "#41d1db",
      "500": "#00b7c3",
      "600": "#00757c",
      "700": "#006167",
      "800": "#004d51",
      "900": "#003c3c",
      "000": "#def4f4",
    },
    purple: {
      "100": "#e6b6ec",
      "200": "#d585e0",
      "300": "#c75cd6",
      "400": "#b933cc",
      "500": "#9f1bb1",
      "600": "#881798",
      "700": "#690976",
      "800": "#54075f",
      "900": "#3f0547",
      "000": "#f4def7",
    },
    green: {
      "100": "#dfe9df",
      "200": "#c7e0c7",
      "300": "#bad80a",
      "400": "#92c353",
      "500": "#7fb800",
      "600": "#55a362",
      "700": "#107c10",
      "800": "#014f01",
      "900": "#393d1b",
      "000": "#dff6dd",
    },
    yellow: {
      "100": "#fef7b2",
      "200": "#f8ed86",
      "300": "#fded68",
      "400": "#fce100",
      "500": "#eed604",
      "600": "#dfc800",
      "700": "#b18525",
      "800": "#986f0b",
      "900": "#795703",
      "000": "#fffbdc",
    },
    red: {
      "100": "#fbcfcf",
      "200": "#f09c9c",
      "300": "#e81123",
      "400": "#da0a00",
      "500": "#da0a00",
      "600": "#cb0f1f",
      "700": "#b80e1c",
      "800": "#a80000",
      "900": "#810b0b",
      "000": "#f9ebeb",
    },
    orange: {
      "100": "#ffeec5",
      "200": "#ffe4a3",
      "300": "#ffd265",
      "400": "#fdb913",
      "500": "#d67f3c",
      "600": "#d67730",
      "700": "#b85e06",
      "800": "#ab6630",
      "900": "#433519",
      "000": "#fbf2ec",
    },
    magenta: {
      "100": "#fbb6e1",
      "200": "#f87cc9",
      "300": "#f53dae",
      "400": "#e3008c",
      "500": "#cc007e",
      "600": "#b2006e",
      "700": "#9b0062",
      "800": "#660040",
      "900": "#4d0030",
      "000": "#fee7f5",
    },
    lime: {
      "100": "#dcf4a4",
      "200": "#c5ec6a",
      "300": "#b9e84a",
      "400": "#a8e21d",
      "500": "#8cbd18",
      "600": "#769f14",
      "700": "#54710e",
      "800": "#425a0c",
      "900": "#324409",
      "000": "#edf9d1",
    },
  },
  elevation: {
    surface: {
      default: {
        "[default]": "#ffffff",
        hovered: "#0000001a",
        pressed: "#00000033",
      },
      sunken: "#f4f4f4",
      raised: {
        default: "#ffffff",
        hovered: "#f4f4f4",
        pressed: "#deecf9",
      },
      overlay: {
        default: "#ffffff",
        hovered: "#f4f4f4",
        pressed: "#efefef",
      },
    },
    shadow: {
      raised: "0px 1.6px 3.6px 0px #00000022, 0px 0.3px 0.9px 0px #0000001c",
      overlay: "0px 6.4px 14.4px 0px #00000022, 0px 1.2px 3.6px 0px #0000001c",
      overflow: {
        default: "0px 0px 12px 0px #0304048f, 0px 0px 1px 0px #03040480",
        spread: "#091e4229",
        perimeter: "#091e421f",
      },
    },
  },
  border: {
    default: "#00000014",
    bold: "#00000033",
    selected: "#0078d4",
    focused: "#0078d4",
    subtle: "#00000014",
    input: "#00000099",
    inverse: "#ffffff",
    disabled: "#0000001a",
    brand: "#0078d4",
    danger: "#da0a00",
    warning: "#d67730",
    success: "#55a362",
    discovery: "#881798",
    information: "#0067b5",
    accent: {
      blue: "#0067b5",
      red: "#e81123",
      orange: "#d67730",
      yellow: "#dfc800",
      green: "#55a362",
      magenta: "#cc007e",
      purple: "#881798",
      teal: "#00b7c3",
      gray: "#c8c8c8",
    },
    table: {
      default: "#00000005",
      container: "#00000014",
    },
  },
  background: {
    accent: {
      blue: {
        subtlest: "#eff6fc",
        subtler: "#deecf9",
        subtle: "#2b88d8",
        bolder: "#0067b5",
      },
      red: {
        subtlest: "#f9ebeb",
        subtler: "#fbcfcf",
        subtle: "#e81123",
        bolder: "#da0a00",
      },
      orange: {
        subtlest: "#fbf2ec",
        subtler: "#ffeec5",
        subtle: "#ffd265",
        bolder: "#d67730",
      },
      yellow: {
        subtlest: "#fffbdc",
        subtler: "#fef7b2",
        subtle: "#fded68",
        bolder: "#dfc800",
      },
      green: {
        subtlest: "#dff6dd",
        subtler: "#dfe9df",
        subtle: "#bad80a",
        bolder: "#7fb800",
      },
      purple: {
        subtlest: "#f4def7",
        subtler: "#e6b6ec",
        subtle: "#c75cd6",
        bolder: "#881798",
      },
      teal: {
        subtlest: "#def4f4",
        subtler: "#caedec",
        subtle: "#70e2df",
        bolder: "#00757c",
      },
      magenta: {
        subtlest: "#fee7f5",
        subtler: "#fbb6e1",
        subtle: "#f53dae",
        bolder: "#b2006e",
      },
      lime: {
        subtlest: "#edf9d1",
        subtler: "#dcf4a4",
        subtle: "#b9e84a",
        bolder: "#769f14",
      },
      gray: {
        subtlest: {
          default: "#ffffff",
          hovered: "#f8f8f8",
          pressed: "#00000033",
        },
        subtler: {
          default: "#efefef",
          hovered: "#eaeaea",
          pressed: "#dadada",
        },
        subtle: {
          default: "#dadada",
          hovered: "#efefef",
          pressed: "#dadada",
        },
        bolder: {
          default: "#a6a6a6",
          hovered: "#4c4c4c",
          pressed: "#333333",
        },
      },
    },
    input: {
      default: "#ffffff",
      hovered: "#f8f8f8",
      pressed: "#ffffff",
    },
    inverse: {
      subtle: {
        default: "#00000029",
        hovered: "#0000003d",
        pressed: "#00000052",
      },
    },
    neutral: {
      default: {
        "[default]": "#efefef",
        hovered: "#f4f4f4",
        pressed: "#00000033",
      },
      subtle: {
        default: "#00000000",
        hovered: "#f4f4f4",
        pressed: "#00000033",
      },
      bold: {
        default: "#333333",
        hovered: "#4c4c4c",
        pressed: "#005ba1",
      },
    },
    brand: {
      subtlest: {
        default: "#eff6fc",
        hovered: "#deecf9",
        pressed: "#c7e0f4",
      },
      bold: {
        default: "#0078d4",
        hovered: "#0067b5",
        pressed: "#005ba1",
      },
      boldest: {
        default: "#004578",
        hovered: "#005ba1",
        pressed: "#005a9e",
      },
    },
    selected: {
      default: {
        "[default]": "#f4f4f4",
        hovered: "#eff6fc",
        pressed: "#2b88d8",
      },
      bold: {
        default: "#0067b5",
        hovered: "#005a9e",
        pressed: "#005ba1",
      },
    },
    disabled: "#0000000a",
    information: {
      default: {
        "[default]": "#deecf9",
        hovered: "#f4f4f4",
        pressed: "#a6a6a6",
      },
      bold: {
        default: "#4c4c4c",
        hovered: "#106ebe",
        pressed: "#0067b5",
      },
    },
    danger: {
      default: {
        "[default]": "#f9ebeb",
        hovered: "#fbcfcf",
        pressed: "#f09c9c",
      },
      bold: {
        default: "#da0a00",
        hovered: "#da0a00",
        pressed: "#a80000",
      },
    },
    success: {
      default: {
        "[default]": "#dff6dd",
        hovered: "#dfe9df",
        pressed: "#c7e0c7",
      },
      bold: {
        default: "#7fb800",
        hovered: "#107c10",
        pressed: "#014f01",
      },
    },
    discovery: {
      default: {
        "[default]": "#f4def7",
        hovered: "#e6b6ec",
        pressed: "#d585e0",
      },
      bold: {
        default: "#881798",
        hovered: "#690976",
        pressed: "#54075f",
      },
    },
    warning: {
      default: {
        "[default]": "#fbf2ec",
        hovered: "#d67f3c",
        pressed: "#ffeec5",
      },
      bold: {
        default: "#d67f3c",
        hovered: "#d67f3c",
        pressed: "#b85e06",
      },
    },
  },
  blanket: {
    default: "#00000033",
    selected: "#388bff14",
    danger: "#ef5c4814",
  },
  text: {
    default: "#000000e6",
    subtle: "#0000008c",
    subtlest: "#4c4c4c",
    disabled: "#00000061",
    selected: "#005ba1",
    inverse: "#ffffff",
    success: "#7fb800",
    danger: "#da0a00",
    information: "#005a9e",
    warning: {
      default: "#d67f3c",
      inverse: "#333333",
    },
    discovery: "#690976",
    brand: "#0067b5",
    accent: {
      blue: {
        default: "#005a9e",
        bolder: "#005ba1",
      },
      red: {
        default: "#b80e1c",
        bolder: "#a80000",
      },
      orange: {
        default: "#b85e06",
        bolder: "#ab6630",
      },
      yellow: {
        default: "#b18525",
        bolder: "#986f0b",
      },
      green: {
        default: "#107c10",
        bolder: "#014f01",
      },
      purple: {
        default: "#690976",
        bolder: "#54075f",
      },
      teal: {
        default: "#006167",
        bolder: "#004d51",
      },
      magenta: {
        default: "#9b0062",
        bolder: "#660040",
      },
      gray: {
        default: "#666666",
        bolder: "#000000",
      },
      lime: {
        default: "#54710e",
        bolder: "#425a0c",
      },
    },
  },
  link: {
    default: "#106ebe",
    hovered: "#106ebe",
    pressed: "#106ebe",
    visited: "#106ebe",
  },
  icon: {
    accent: {
      blue: "#106ebe",
      red: "#da0a00",
      orange: "#d67f3c",
      yellow: "#eed604",
      green: "#7fb800",
      purple: "#9f1bb1",
      teal: "#00b7c3",
      magenta: "#cc007e",
      gray: "#c8c8c8",
      lime: "#8cbd18",
    },
    default: "#000000e6",
    subtle: "#666666",
    inverse: "#ffffff",
    disabled: "#dadada",
    brand: "#0078d4",
    selected: "#005a9e",
    danger: "#da0a00",
    success: "#55a362",
    discovery: "#881798",
    information: "#0078d4",
    warning: {
      default: "#d67f3c",
      inverse: "#333333",
    },
  },
  interaction: {
    hovered: "#00000029",
    pressed: "#00000052",
  },
  skeleton: {
    default: "#0000000f",
    subtle: "#0000000a",
  },
  opacity: {
    disabled: 0.2,
    loading: 0.4,
  },
  chart: {
    brand: {
      default: "#106ebe",
      hovered: "#0067b5",
    },
    neutral: {
      default: "#dadada",
      hovered: "#c8c8c8",
    },
    success: {
      default: {
        "[default]": "#7fb800",
        hovered: "#55a362",
      },
      bold: {
        default: "#107c10",
        hovered: "#014f01",
      },
    },
    danger: {
      default: {
        "[default]": "#da0a00",
        hovered: "#da0a00",
      },
      bold: {
        default: "#b80e1c",
        hovered: "#a80000",
      },
    },
    warning: {
      default: {
        "[default]": "#eed604",
        hovered: "#dfc800",
      },
      bold: {
        default: "#b18525",
        hovered: "#986f0b",
      },
    },
    information: {
      default: {
        "[default]": "#106ebe",
        hovered: "#0067b5",
      },
      bold: {
        default: "#005a9e",
        hovered: "#005ba1",
      },
    },
    discovery: {
      default: {
        "[default]": "#b933cc",
        hovered: "#9f1bb1",
      },
      bold: {
        default: "#690976",
        hovered: "#54075f",
      },
    },
    categorical: {
      "1": {
        default: "#00b7c3",
        hovered: "#00757c",
      },
      "2": {
        default: "#690976",
        hovered: "#54075f",
      },
      "3": {
        default: "#d67f3c",
        hovered: "#d67730",
      },
      "4": {
        default: "#9b0062",
        hovered: "#660040",
      },
      "5": {
        default: "#005ba1",
        hovered: "#004578",
      },
      "6": {
        default: "#b933cc",
        hovered: "#9f1bb1",
      },
      "7": {
        default: "#660040",
        hovered: "#4d0030",
      },
      "8": {
        default: "#b85e06",
        hovered: "#ab6630",
      },
    },
    blue: {
      bold: {
        default: "#0078d4",
        hovered: "#0078d4",
      },
      bolder: {
        default: "#106ebe",
        hovered: "#0067b5",
      },
      boldest: {
        default: "#005a9e",
        hovered: "#005ba1",
      },
    },
    red: {
      bold: {
        default: "#da0a00",
        hovered: "#da0a00",
      },
      bolder: {
        default: "#da0a00",
        hovered: "#cb0f1f",
      },
      boldest: {
        default: "#b80e1c",
        hovered: "#a80000",
      },
    },
    orange: {
      bold: {
        default: "#d67f3c",
        hovered: "#d67730",
      },
      bolder: {
        default: "#d67730",
        hovered: "#b85e06",
      },
      boldest: {
        default: "#b85e06",
        hovered: "#ab6630",
      },
    },
    yellow: {
      bold: {
        default: "#eed604",
        hovered: "#dfc800",
      },
      bolder: {
        default: "#dfc800",
        hovered: "#b18525",
      },
      boldest: {
        default: "#b18525",
        hovered: "#986f0b",
      },
    },
    green: {
      bold: {
        default: "#7fb800",
        hovered: "#55a362",
      },
      bolder: {
        default: "#55a362",
        hovered: "#107c10",
      },
      boldest: {
        default: "#107c10",
        hovered: "#014f01",
      },
    },
    teal: {
      bold: {
        default: "#00b7c3",
        hovered: "#00757c",
      },
      bolder: {
        default: "#00757c",
        hovered: "#006167",
      },
      boldest: {
        default: "#006167",
        hovered: "#004d51",
      },
    },
    purple: {
      bold: {
        default: "#b933cc",
        hovered: "#9f1bb1",
      },
      bolder: {
        default: "#9f1bb1",
        hovered: "#881798",
      },
      boldest: {
        default: "#690976",
        hovered: "#54075f",
      },
    },
    magenta: {
      bold: {
        default: "#e3008c",
        hovered: "#cc007e",
      },
      bolder: {
        default: "#cc007e",
        hovered: "#b2006e",
      },
      boldest: {
        default: "#9b0062",
        hovered: "#660040",
      },
    },
    gray: {
      bold: {
        default: "#dadada",
        hovered: "#c8c8c8",
      },
      bolder: {
        default: "#c8c8c8",
        hovered: "#a6a6a6",
      },
      boldest: {
        default: "#666666",
        hovered: "#4c4c4c",
      },
    },
    lime: {
      bold: {
        default: "#8cbd18",
        hovered: "#769f14",
      },
      bolder: {
        default: "#769f14",
        hovered: "#54710e",
      },
      boldest: {
        default: "#54710e",
        hovered: "#425a0c",
      },
    },
  },
};
