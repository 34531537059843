/**
 * @fuegokit/tokens 0.26.0
 * Do not edit directly
 * Generated on Thu, 03 Apr 2025 22:20:56 GMT
 */
export default {
  scale: {
    orange: {
      "100": "#ffd6c4",
      "200": "#ffad89",
      "300": "#ff996c",
      "400": "#ed7c4a",
      "500": "#be633b",
      "600": "#8e4a2c",
      "700": "#5f321e",
      "800": "#2f190f",
      "900": "#180c07",
      "000": "#fff5f0",
    },
    blue: {
      "100": "#b0caff",
      "200": "#6195ff",
      "300": "#397bff",
      "400": "#336fe6",
      "500": "#2256c5",
      "600": "#224a99",
      "700": "#173166",
      "800": "#0c1933",
      "900": "#070f1f",
      "000": "#ebf2ff",
    },
    teal: {
      "100": "#d1fef9",
      "200": "#a4fdf4",
      "300": "#8dfcf1",
      "400": "#7fe3d9",
      "500": "#71cac1",
      "600": "#559791",
      "700": "#386560",
      "800": "#1c3330",
      "900": "#111e1d",
      "000": "#f4fffe",
    },
    purple: {
      "100": "#ddcbff",
      "200": "#ba97ff",
      "300": "#a97dff",
      "400": "#9871e6",
      "500": "#8764cc",
      "600": "#654b99",
      "700": "#443266",
      "800": "#221933",
      "900": "#140f1f",
      "000": "#f6f2ff",
    },
    white: "#ffffff",
    black: "#1e1e1e",
    yellow: {
      "100": "#fffbe2",
      "200": "#fff8c5",
      "300": "#fff6b6",
      "400": "#ffee70",
      "500": "#f0da4e",
      "600": "#c0ae3e",
      "700": "#90832f",
      "800": "#484117",
      "900": "#181608",
      "000": "#fffef8",
    },
    red: {
      "100": "#fbb4b4",
      "200": "#f66969",
      "300": "#f44343",
      "400": "#dc3c3c",
      "500": "#c33636",
      "600": "#922828",
      "700": "#621b1b",
      "800": "#310e0e",
      "900": "#1d0808",
      "000": "#feecec",
    },
    green: {
      "100": "#b7dfb9",
      "200": "#b7dfb9",
      "300": "#4caf50",
      "400": "#449e48",
      "500": "#358238",
      "600": "#2e6930",
      "700": "#1e4620",
      "800": "#0f2310",
      "900": "#09150a",
      "000": "#edf7ee",
    },
    neutral: {
      "100": "#f3f1f6",
      "200": "#e6e3ec",
      "300": "#cfccd4",
      "400": "#b8b6bd",
      "500": "#a19fa5",
      "600": "#8a888e",
      "700": "#5c5b5e",
      "800": "#2e2d2f",
      "900": "#1c1b1c",
      "1000": "#121213",
      "1100": "#090a0f",
      "000": "#faf9fb",
    },
    neutralAlpha: {
      "100": "#0000000d",
      "200": "#0000001a",
      "300": "#00000033",
      "400": "#0000004d",
      "500": "#00000080",
      "600": "#00000099",
      "700": "#000000b3",
      "800": "#000000cc",
      "900": "#000000e6",
      "000": "#00000005",
    },
    gradient: {
      orangeYellow: "linear-gradient(90deg, #FF996C 0%, #FFF6B6 100%)",
      yellowPurple: "linear-gradient(90deg, #FFF6B6 0%, #A97DFF 100%)",
      bluePurple: "linear-gradient(90deg, #397BFF 0%, #A97DFF 100%)",
      yellowBlue: "linear-gradient(90deg, #FFF6B6 0%, #397BFF 100%)",
      orangeTeal: "linear-gradient(90deg, #FF996C 0%, #8DFCF1 99%)",
      orangePurple: "linear-gradient(90deg, #FF996C 0%, #A97DFF 100%)",
      blueOrange: "linear-gradient(90deg, #397BFF 0%, #FF996C 99%)",
      purpleTeal: "linear-gradient(90deg, #A97DFF 0%, #8DFCF1 99%)",
      tealBlue: "linear-gradient(90deg, #8DFCF1 0%, #397BFF 100%)",
      tealYellow: "linear-gradient(90deg, #8DFCF1 0%, #FFF6B6 100%)",
    },
    lime: {
      "100": "#e0ffbf",
      "200": "#c5ff8a",
      "300": "#abf560",
      "400": "#8fdd3c",
      "500": "#75c727",
      "600": "#5bab1d",
      "700": "#428714",
      "800": "#2d5f0e",
      "900": "#1a3a08",
      "000": "#f5ffe7",
    },
    magenta: {
      "100": "#ffd9f4",
      "200": "#ffa5e4",
      "300": "#ff74d1",
      "400": "#e95bc0",
      "500": "#d35ebf",
      "600": "#b34ba2",
      "700": "#8f3a82",
      "800": "#63275a",
      "900": "#41183b",
      "000": "#fff0fb",
    },
    gray: {
      "100": "#ededed",
      "200": "#e0e0e2",
      "300": "#d1d1d5",
      "400": "#b3b3b8",
      "500": "#96969c",
      "600": "#78787f",
      "700": "#5a5a60",
      "800": "#3d3d42",
      "900": "#27272a",
      "000": "#f9f9fa",
    },
  },
  text: {
    default: "#1c1b1c",
    subtle: "#2e2d2f",
    subtlest: "#8a888e",
    disabled: "#a19fa5",
    selected: "#2256c5",
    inverse: "#ffffff",
    success: "#358238",
    danger: "#c33636",
    information: "#224a99",
    warning: {
      default: "#181608",
      inverse: "#121213",
    },
    discovery: "#443266",
    brand: "#397bff",
    accent: {
      blue: {
        default: "#336fe6",
        bolder: "#224a99",
      },
      red: {
        default: "#f44343",
        bolder: "#c33636",
      },
      orange: {
        default: "#ed7c4a",
        bolder: "#be633b",
      },
      yellow: {
        default: "#c0ae3e",
        bolder: "#90832f",
      },
      green: {
        default: "#449e48",
        bolder: "#2e6930",
      },
      purple: {
        default: "#8764cc",
        bolder: "#654b99",
      },
      teal: {
        default: "#71cac1",
        bolder: "#386560",
      },
      gray: {
        default: "#8a888e",
        bolder: "#1c1b1c",
      },
      lime: {
        default: "#428714",
        bolder: "#2d5f0e",
      },
      magenta: {
        default: "#8f3a82",
        bolder: "#63275a",
      },
    },
  },
  link: {
    default: "#397bff",
    hovered: "#397bff",
    pressed: "#6195ff",
    visited: "#ba97ff",
  },
  icon: {
    brand: "#397bff",
    inverse: "#f3f1f6",
    danger: "#f44343",
    success: "#4caf50",
    warning: "#be633b",
    disabled: "#a19fa5",
    accent: {
      blue: "#397bff",
      red: "#f44343",
      orange: "#ff996c",
      yellow: "#ffee70",
      green: "#4caf50",
      purple: "#9871e6",
      teal: "#7fe3d9",
      gray: "#b8b6bd",
      lime: "#75c727",
      magenta: "#d35ebf",
    },
    default: "#121213",
    subtle: "#5c5b5e",
    discovery: "#8764cc",
    information: "#2256c5",
    selected: "#224a99",
  },
  border: {
    default: "#e6e3ec",
    bold: "#8a888e",
    selected: "#2256c5",
    focused: "#6195ff",
    subtle: "#b8b6bd",
    inverse: "#faf9fb",
    danger: "#f44343",
    success: "#4caf50",
    input: "#ffffff",
    disabled: "#0000004d",
    discovery: "#8764cc",
    brand: "#397bff",
    information: "#2256c5",
    warning: "#be633b",
    accent: {
      blue: "#397bff",
      red: "#f44343",
      orange: "#ff996c",
      yellow: "#ffee70",
      green: "#4caf50",
      purple: "#a97dff",
      teal: "#7fe3d9",
      gray: "#a19fa5",
      magenta: "#d35ebf",
    },
    table: {
      default: "#00000033",
      container: "#00000005",
    },
  },
  background: {
    brand: {
      subtlest: {
        default: "#ebf2ff",
        hovered: "#b0caff",
        pressed: "#6195ff",
      },
      bold: {
        default: "#224a99",
        hovered: "#173166",
        pressed: "#0c1933",
      },
      boldest: {
        default: "#070f1f",
        hovered: "#0c1933",
        pressed: "#173166",
      },
    },
    disabled: "#0000001a",
    information: {
      default: {
        "[default]": "#ebf2ff",
        hovered: "#b0caff",
        pressed: "#6195ff",
      },
      bold: {
        default: "#2256c5",
        hovered: "#173166",
        pressed: "#0c1933",
      },
    },
    danger: {
      default: {
        "[default]": "#feecec",
        hovered: "#fbb4b4",
        pressed: "#f66969",
      },
      bold: {
        default: "#922828",
        hovered: "#621b1b",
        pressed: "#310e0e",
      },
    },
    success: {
      default: {
        "[default]": "#edf7ee",
        hovered: "#b7dfb9",
        pressed: "#b7dfb9",
      },
      bold: {
        default: "#2e6930",
        hovered: "#1e4620",
        pressed: "#0f2310",
      },
    },
    discovery: {
      default: {
        "[default]": "#f6f2ff",
        hovered: "#ddcbff",
        pressed: "#ba97ff",
      },
      bold: {
        default: "#654b99",
        hovered: "#443266",
        pressed: "#221933",
      },
    },
    warning: {
      default: {
        "[default]": "#fffef8",
        hovered: "#fffbe2",
        pressed: "#fffbe2",
      },
      bold: {
        default: "#fff8c5",
        hovered: "#fff6b6",
        pressed: "#ffee70",
      },
    },
    accent: {
      blue: {
        subtlest: "#0c1933",
        subtler: "#224a99",
        subtle: "#2256c5",
        bolder: "#397bff",
      },
      red: {
        subtlest: "#310e0e",
        subtler: "#621b1b",
        subtle: "#922828",
        bolder: "#f44343",
      },
      orange: {
        subtlest: "#2f190f",
        subtler: "#8e4a2c",
        subtle: "#be633b",
        bolder: "#ff996c",
      },
      yellow: {
        subtlest: "#484117",
        subtler: "#c0ae3e",
        subtle: "#f0da4e",
        bolder: "#fff6b6",
      },
      green: {
        subtlest: "#0f2310",
        subtler: "#2e6930",
        subtle: "#358238",
        bolder: "#4caf50",
      },
      teal: {
        subtlest: "#1c3330",
        subtler: "#559791",
        subtle: "#71cac1",
        bolder: "#8dfcf1",
      },
      purple: {
        subtlest: "#221933",
        subtler: "#654b99",
        subtle: "#8764cc",
        bolder: "#a97dff",
      },
      gray: {
        subtlest: {
          default: "#e6e3ec",
          hovered: "#cfccd4",
          pressed: "#cfccd4",
        },
        subtler: {
          default: "#cfccd4",
          hovered: "#b8b6bd",
          pressed: "#a19fa5",
        },
        subtle: {
          default: "#a19fa5",
          hovered: "#8a888e",
          pressed: "#8a888e",
        },
        bolder: {
          default: "#5c5b5e",
          hovered: "#2e2d2f",
          pressed: "#1c1b1c",
        },
      },
      gradient: {
        orangeYellow: "linear-gradient(90deg, #FF996C 0%, #FFF6B6 100%)",
        yellowPurple: "linear-gradient(90deg, #FFF6B6 0%, #A97DFF 100%)",
        bluePurple: "linear-gradient(90deg, #397BFF 0%, #A97DFF 100%)",
        yellowBlue: "linear-gradient(90deg, #FFF6B6 0%, #397BFF 100%)",
        orangeTeal: "linear-gradient(90deg, #FF996C 0%, #8DFCF1 99%)",
        orangePurple: "linear-gradient(90deg, #FF996C 0%, #A97DFF 100%)",
        blueOrange: "linear-gradient(90deg, #397BFF 0%, #FF996C 99%)",
        purpleTeal: "linear-gradient(90deg, #A97DFF 0%, #8DFCF1 99%)",
        tealBlue: "linear-gradient(90deg, #8DFCF1 0%, #397BFF 100%)",
        tealYellow: "linear-gradient(90deg, #8DFCF1 0%, #FFF6B6 100%)",
      },
      magenta: {
        subtlest: "#fff0fb",
        subtler: "#ffd9f4",
        subtle: "#ff74d1",
        bolder: "#b34ba2",
      },
      lime: {
        subtlest: "#f5ffe7",
        subtler: "#e0ffbf",
        subtle: "#abf560",
        bolder: "#5bab1d",
      },
    },
    neutral: {
      default: {
        "[default]": "#0000001a",
        hovered: "#00000033",
        pressed: "#0000004d",
      },
      subtle: {
        default: "#00000005",
        hovered: "#0000001a",
        pressed: "#00000033",
      },
      bold: {
        default: "#2e2d2f",
        hovered: "#1c1b1c",
        pressed: "#121213",
      },
    },
    selected: {
      default: {
        "[default]": "#6195ff",
        hovered: "#397bff",
        pressed: "#336fe6",
      },
      bold: {
        default: "#224a99",
        hovered: "#173166",
        pressed: "#0c1933",
      },
    },
    input: {
      default: "#faf9fb",
      hovered: "#f3f1f6",
      pressed: "#faf9fb",
    },
    inverse: {
      subtle: {
        default: "#00000029",
        hovered: "#0000003d",
        pressed: "#00000052",
      },
    },
  },
  blanket: {
    default: "#00000080",
    selected: "#388bff14",
    danger: "#ef5c4814",
  },
  elevation: {
    surface: {
      default: {
        "[default]": "#ffffff",
        hovered: "#e6e3ec",
        pressed: "#cfccd4",
      },
      sunken: "#f3f1f6",
      raised: {
        default: "#f3f1f6",
        hovered: "#e6e3ec",
        pressed: "#cfccd4",
      },
      overlay: {
        default: "#faf9fb",
        hovered: "#e6e3ec",
        pressed: "#cfccd4",
      },
    },
    shadow: {
      raised: "0px 1px 1px 0px #0c193340, 0px 0px 1px 0px #0c19334f",
      overflow: {
        default: "0px 0px 12px 0px #1731664f, 0px 0px 1px 0px #03040480",
        spread: "#0c193329",
        perimeter: "#1731661f",
      },
      overlay: "0px 8px 12px 0px #0c193326, 0px 0px 1px 0px #0c19334f",
    },
  },
  interaction: {
    hovered: "#00000029",
    pressed: "#00000052",
  },
  skeleton: {
    default: "#0000001a",
    subtle: "#0000000d",
  },
  opacity: {
    disabled: 0.38,
    loading: 0.7,
  },
  chart: {
    brand: {
      default: "#2256c5",
      hovered: "#224a99",
    },
    neutral: {
      default: "#a19fa5",
      hovered: "#8a888e",
    },
    success: {
      default: {
        "[default]": "#358238",
        hovered: "#2e6930",
      },
      bold: {
        default: "#1e4620",
        hovered: "#0f2310",
      },
    },
    danger: {
      default: {
        "[default]": "#dc3c3c",
        hovered: "#c33636",
      },
      bold: {
        default: "#621b1b",
        hovered: "#310e0e",
      },
    },
    warning: {
      default: {
        "[default]": "#f0da4e",
        hovered: "#c0ae3e",
      },
      bold: {
        default: "#90832f",
        hovered: "#484117",
      },
    },
    information: {
      default: {
        "[default]": "#2256c5",
        hovered: "#224a99",
      },
      bold: {
        default: "#173166",
        hovered: "#0c1933",
      },
    },
    discovery: {
      default: {
        "[default]": "#9871e6",
        hovered: "#8764cc",
      },
      bold: {
        default: "#443266",
        hovered: "#221933",
      },
    },
    categorical: {
      "1": {
        default: "#71cac1",
        hovered: "#559791",
      },
      "2": {
        default: "#443266",
        hovered: "#221933",
      },
      "3": {
        default: "#be633b",
        hovered: "#8e4a2c",
      },
      "4": {
        default: "#8f3a82",
        hovered: "#63275a",
      },
      "5": {
        default: "#0c1933",
        hovered: "#070f1f",
      },
      "6": {
        default: "#9871e6",
        hovered: "#8764cc",
      },
      "7": {
        default: "#63275a",
        hovered: "#41183b",
      },
      "8": {
        default: "#5f321e",
        hovered: "#2f190f",
      },
    },
    blue: {
      bold: {
        default: "#336fe6",
        hovered: "#336fe6",
      },
      bolder: {
        default: "#2256c5",
        hovered: "#224a99",
      },
      boldest: {
        default: "#173166",
        hovered: "#0c1933",
      },
    },
    red: {
      bold: {
        default: "#dc3c3c",
        hovered: "#c33636",
      },
      bolder: {
        default: "#c33636",
        hovered: "#922828",
      },
      boldest: {
        default: "#621b1b",
        hovered: "#310e0e",
      },
    },
    orange: {
      bold: {
        default: "#be633b",
        hovered: "#8e4a2c",
      },
      bolder: {
        default: "#8e4a2c",
        hovered: "#5f321e",
      },
      boldest: {
        default: "#5f321e",
        hovered: "#2f190f",
      },
    },
    yellow: {
      bold: {
        default: "#f0da4e",
        hovered: "#c0ae3e",
      },
      bolder: {
        default: "#c0ae3e",
        hovered: "#90832f",
      },
      boldest: {
        default: "#90832f",
        hovered: "#484117",
      },
    },
    green: {
      bold: {
        default: "#358238",
        hovered: "#2e6930",
      },
      bolder: {
        default: "#2e6930",
        hovered: "#1e4620",
      },
      boldest: {
        default: "#1e4620",
        hovered: "#0f2310",
      },
    },
    teal: {
      bold: {
        default: "#71cac1",
        hovered: "#559791",
      },
      bolder: {
        default: "#559791",
        hovered: "#386560",
      },
      boldest: {
        default: "#386560",
        hovered: "#1c3330",
      },
    },
    purple: {
      bold: {
        default: "#9871e6",
        hovered: "#8764cc",
      },
      bolder: {
        default: "#8764cc",
        hovered: "#654b99",
      },
      boldest: {
        default: "#443266",
        hovered: "#221933",
      },
    },
    magenta: {
      bold: {
        default: "#e95bc0",
        hovered: "#d35ebf",
      },
      bolder: {
        default: "#d35ebf",
        hovered: "#b34ba2",
      },
      boldest: {
        default: "#8f3a82",
        hovered: "#63275a",
      },
    },
    gray: {
      bold: {
        default: "#a19fa5",
        hovered: "#8a888e",
      },
      bolder: {
        default: "#8a888e",
        hovered: "#5c5b5e",
      },
      boldest: {
        default: "#2e2d2f",
        hovered: "#1c1b1c",
      },
    },
    lime: {
      bold: {
        default: "#75c727",
        hovered: "#5bab1d",
      },
      bolder: {
        default: "#5bab1d",
        hovered: "#428714",
      },
      boldest: {
        default: "#428714",
        hovered: "#2d5f0e",
      },
    },
  },
};
