/**
 * @fuegokit/tokens 0.26.0
 * Do not edit directly
 * Generated on Thu, 03 Apr 2025 22:21:01 GMT
 */
export default {
  radii: {
    "0": "0px",
    "1": "3px",
    "2": "4px",
    "3": "6px",
    "4": "8px",
    "5": "9999999px",
    none: "0px",
    xsmall: "3px",
    small: "4px",
    medium: "6px",
    large: "8px",
    xlarge: "16px",
    round: "9999999px",
  },
};
