import React from "react";
import styled, { keyframes, css } from "styled-components";
import { Link as GatsbyLink } from "gatsby";

import { DialogContent, DialogOverlay } from "@reach/dialog";
import {
  AtlasButton,
  Box,
  Text,
  Link,
  Logo,
  themeGet,
  VisuallyHidden,
  useTheme,
} from "@fuegokit/react";
import { Search } from "../Search";
import useSiteMetadata from "../../hooks/use-site-metadata";
import fuegokitNavItems from "../../fuegokit-nav.yml";
import SidePanel from "../SidePanel";
import { HeaderNavDropdown } from "../HeaderNavDropdown";
import { AfMenuIcon } from "@fuegokit/fuegoicons-react";
import { ColorModeToggle } from "../ColorModeToggle";

export const HEADER_HEIGHT = 84;

const Header = ({ packageName }) => {
  const siteMetadata = useSiteMetadata();
  const [isExpanded, setIsExpanded] = React.useState(false);

  const docsTheme = packageName === "website" ? "brand" : "default";

  return (
    <>
      <NavbarWrapper packageName={packageName}>
        <Navbar aria-labelledby="mainmenulabel" packageName={packageName}>
          <DesktopNavigation>
            {siteMetadata.siteName && siteMetadata.siteName === "Fuegokit" ? (
              <StyledLink
                href="https://fuegokit.design"
                sx={{
                  color: "text.default",
                  mr: 2,
                  lineHeight: "condensedUltra",
                }}
              >
                <Box
                  sx={{
                    color: "text.default",
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Logo
                    variant="solid"
                    size={16}
                    color={"currentColor"}
                    aria-hidden="true"
                  />
                  Fuegokit
                </Box>
              </StyledLink>
            ) : null}

            {siteMetadata.siteName && siteMetadata.siteName !== "Fuegokit" ? (
              <>
                <StyledLink
                  href="https://fuegokit.design"
                  sx={{
                    display: "inline-block",
                    color: "text.default",
                    mr: 1,
                  }}
                >
                  Fuegokit
                </StyledLink>
                <Text
                  sx={{
                    display: "inline-block",
                    color: "text.default",
                    mx: 2,
                  }}
                >
                  /
                </Text>
                <StyledLink
                  as={GatsbyLink}
                  to="/"
                  sx={{
                    width: "max-content",
                    color: "text.default",
                    fontWeight: "semibold",
                  }}
                >
                  {siteMetadata.siteName}
                </StyledLink>
              </>
            ) : null}
          </DesktopNavigation>

          <MobileNavigation>
            {siteMetadata.siteName && siteMetadata.siteName !== "Fuegokit" ? (
              <AtlasButton
                spacing="default"
                appearance={"subtle"}
                onClick={() => setIsExpanded(!isExpanded)}
              >
                <VisuallyHidden>Open menu</VisuallyHidden>
                <AfMenuIcon size={"small"} />
              </AtlasButton>
            ) : null}

            {siteMetadata.siteName && siteMetadata.siteName === "Fuegokit" ? (
              <StyledLink
                href="https://fuegokit.design"
                sx={{
                  color: "text.default",
                  mr: 2,
                  lineHeight: "condensedUltra",
                }}
              >
                <Box
                  sx={{
                    color: "text.default",
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Logo
                    variant="solid"
                    size={16}
                    color={"currentColor"}
                    aria-hidden="true"
                  />
                  Fuegokit
                </Box>
              </StyledLink>
            ) : null}
            {siteMetadata.siteName && siteMetadata.siteName !== "Fuegokit" ? (
              <>
                <StyledLink
                  as={GatsbyLink}
                  to="/"
                  sx={{ width: "max-content", color: "text.default" }}
                >
                  {siteMetadata.siteName}
                </StyledLink>
              </>
            ) : null}
          </MobileNavigation>

          <DesktopActions>
            <Box sx={{ display: ["none", "none", "none", "block"] }}>
              {siteMetadata && siteMetadata.isSearchEnabled ? <Search /> : null}
            </Box>

            <StyledLink
              href="https://fuegokit.design/news"
              sx={{
                display: "inline-block",
                color: "inherit",
                ml: 4,
              }}
            >
              What&apos;s new
            </StyledLink>
            <StyledLink
              href={`/patterns`}
              sx={{
                display: "flex",
                gap: 1,
                color: "inherit",
                ml: 4,

                alignItems: "center",
                color: "text.default",
              }}
            >
              Patterns
            </StyledLink>
            <StyledLink
              href={`/content`}
              sx={{
                display: "flex",
                gap: 1,
                color: "inherit",
                ml: 4,

                alignItems: "center",
                color: "text.default",
              }}
            >
              Content
            </StyledLink>
          </DesktopActions>
          <HeaderActions>
            <HeaderNavDropdown items={fuegokitNavItems} docsTheme={docsTheme} />
            <GitHubLink
              aria-label="GitHub repository"
              href="https://github.com/fuegokit/fuegokit"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_0_5)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.9642 0C5.34833 0 0 5.38776 0 12.0531C0 17.3811 3.42686 21.8912 8.18082 23.4874C8.77518 23.6074 8.9929 23.2281 8.9929 22.909C8.9929 22.6296 8.97331 21.6718 8.97331 20.6738C5.64514 21.3923 4.95208 19.237 4.95208 19.237C4.41722 17.8401 3.62473 17.4811 3.62473 17.4811C2.53543 16.7427 3.70408 16.7427 3.70408 16.7427C4.91241 16.8225 5.54645 17.9799 5.54645 17.9799C6.61592 19.8157 8.33927 19.297 9.03257 18.9776C9.13151 18.1993 9.44865 17.6606 9.78539 17.3613C7.13094 17.0819 4.33812 16.0442 4.33812 11.4144C4.33812 10.0974 4.81322 9.01984 5.56604 8.1818C5.44727 7.88253 5.03118 6.64506 5.68506 4.98882C5.68506 4.98882 6.69527 4.66947 8.97306 6.22604C9.94827 5.9622 10.954 5.82799 11.9642 5.82686C12.9744 5.82686 14.0042 5.96669 14.9552 6.22604C17.2332 4.66947 18.2434 4.98882 18.2434 4.98882C18.8973 6.64506 18.481 7.88253 18.3622 8.1818C19.1349 9.01984 19.5904 10.0974 19.5904 11.4144C19.5904 16.0442 16.7976 17.0618 14.1233 17.3613C14.5592 17.7404 14.9353 18.4587 14.9353 19.5962C14.9353 21.2126 14.9158 22.5098 14.9158 22.9087C14.9158 23.2281 15.1337 23.6074 15.7278 23.4877C20.4818 21.8909 23.9087 17.3811 23.9087 12.0531C23.9282 5.38776 18.5603 0 11.9642 0Z"
                    fill="currentColor"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_0_5">
                    <rect width="24" height="23.5102" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </GitHubLink>
            <ColorModeToggle />
          </HeaderActions>
        </Navbar>
      </NavbarWrapper>

      <MobileNavWrapper
        isOpen={isExpanded}
        onDismiss={() => setIsExpanded(false)}
      >
        <MobileMenuShelf>
          <SidePanel />
        </MobileMenuShelf>
      </MobileNavWrapper>
    </>
  );
};

const NavbarWrapper = styled(Box)`
  position: sticky;
  z-index: 1;
  margin: 0 auto;
  width: 100%;

  ${(props) => {
    switch (props.packageName) {
      case "website":
        return css`
          top: ${themeGet("space.3")};
        `;
      default:
        return css`
          top: 0;
        `;
    }
  }}
`;

const Navbar = styled.nav`
  display: flex;
  height: ${themeGet("size.64")};
  align-items: center;
  justify-content: space-between;
  padding: 0 ${themeGet("space.4")};

  ${(props) => {
    switch (props.packageName) {
      case "website":
        return css`
          border-radius: 50px;
          background-color: ${themeGet("colors.scale.neutralAlpha.300")};
          backdrop-filter: blur(10px);
          a {
            font-size: ${themeGet("fontSize.3")}!important;
          }
          padding: 0 ${themeGet("space.7")};
        `;
      default:
        return css`
          border-radius: ${themeGet("radii.1")};
          background-color: ${themeGet(
            "colors.elevation.surface.default.[default]"
          )};
          border-bottom: 1px solid ${themeGet("colors.border.subtle")};
        `;
    }
  }}
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  opacity: 0.85;
  &:hover {
    opacity: 1;
    text-decoration: none;
  }
`;

const GitHubLink = styled(Link)`
  width: 2rem;
  height: 2rem;
  display: grid;
  place-content: center;
  color: ${themeGet("colors.icon.default")};
  border-radius: ${themeGet("radii.2")};

  &:hover {
    background-color: ${themeGet("colors.background.neutral.subtle.hovered")};
  }
  svg {
    width: 1rem;
  }
`;

const DesktopActions = styled(Box)`
  display: none;
  @media (min-width: ${themeGet("breakpoints.1")}) {
    display: flex;
    margin-left: 24px;
    white-space: nowrap;
    margin-left: auto;
    align-items: center;
  }
`;

const HeaderActions = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${themeGet("space.1")};
`;

const DesktopNavigation = styled(Box)`
  display: none;
  @media (min-width: ${themeGet("breakpoints.1")}) {
    display: flex;
    align-items: center;
  }
`;
const MobileNavigation = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${themeGet("space.1")};
  @media (min-width: ${themeGet("breakpoints.1")}) {
    display: none;
  }
`;

const MobileNavWrapper = styled(DialogOverlay)`
  position: fixed;
  top: 68px;
  left: -16px;
  right: 0;
  bottom: 0;
  background: ${themeGet("colors.blanket.default")};
`;

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%)
  }
`;

const MobileMenuShelf = styled(DialogContent)`
  position: absolute;
  top: -16px;
  left: 0;
  bottom: 0;
  padding-top: ${themeGet("space.3")};
  padding-right: ${themeGet("space.4")};
  padding-bottom: ${themeGet("space.3")};
  padding-left: ${themeGet("space.2")};
  animation: ${slideIn} 500ms;
`;

export default Header;
