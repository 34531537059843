/**
 * @fuegokit/tokens 0.26.0
 * Do not edit directly
 * Generated on Thu, 03 Apr 2025 22:20:27 GMT
 */

export default {
  fontFamily: {
    sansSerif: '"Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    monospace: "Menlo",
    heading: '"Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    display: '"Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    body: '"Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    normal: '"Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    mono: "Menlo",
  },
  fontFamilies: {
    sansSerif: '"Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    monospace: "Menlo",
    title: '"Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    body: '"Segoe UI"',
    normal: '"Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    mono: "Menlo",
  },
  fontStack: {
    sansSerif: '"Segoe UI", "-apple-system", BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    monospace: 'Menlo, Consolas, Monaco, "Liberation Mono", "Lucida Console", monospace',
    title: '"Segoe UI", "-apple-system", BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    body: '"Segoe UI", "-apple-system", BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    normal: '"Segoe UI", "-apple-system", BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    mono: 'Menlo, Consolas, Monaco, "Liberation Mono", "Lucida Console", monospace',
  },
  fontWeight: {
    thin: 100,
    extraLight: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semiBold: 600,
    semibold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
  fontWeights: {
    extraLight: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 600,
    extraBold: 700,
    black: 700,
  },
  fontSize: {
    "0": "9px",
    "1": "10px",
    "2": "11px",
    "3": "12px",
    "4": "13px",
    "5": "14px",
    "6": "15px",
    "7": "16px",
    "8": "18px",
    "9": "24px",
    "10": "28px",
    "11": "56px",
    "12": "72px",
    "13": "84px",
  },
  fontSizes: {
    "0": "9px",
    "1": "10px",
    "2": "11px",
    "3": "12px",
    "4": "13px",
    "5": "14px",
    "6": "15px",
    "7": "16px",
    "8": "18px",
    "9": "24px",
    "10": "28px",
    "11": "56px",
    "12": "72px",
    "13": "84px",
  },
  lineHeight: {
    "0": "16px",
    "1": "20px",
    "2": "24px",
    "3": "32px",
    "4": "40px",
    "5": "44px",
    "6": "78px",
    default: 1.429,
    condensed: 1.143,
    condensedUltra: 1,
    relaxed: 1.714,
  },
  lineHeights: {
    "0": "16px",
    "1": "20px",
    "2": "24px",
    "3": "32px",
    "4": "40px",
    "5": "44px",
    "6": "78px",
    default: 1.429,
    condensed: 1.143,
    condensedUltra: 1,
    relaxed: 1.714,
  },
  letterSpacing: {
    "0": "0px",
    "1": "-0.05px",
    "2": "-0.09px",
    "3": "-0.13px",
    "4": "-0.16px",
  },
  font: {
    heading: {
      xxsmall: '600 12px/16px "Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      xsmall: '600 15px/20px "Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      small: '600 18px/24px "Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      medium: '600 24px/undefined "Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      large: '600 28px/32px "Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      xlarge: '600 56px/undefined "Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      xxlarge: '600 72px/78px "Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },
    body: {
      "[default]": '400 14px/20px "Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      small: '400 12px/16px "Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      medium: '400 14px/20px "Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      large: '400 16px/20px "Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      xlarge: '400 18px/24px "Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },
    ui: {
      "[default]": '600 14px/undefined "Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      small: '600 11px/undefined "Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },
    caption: {
      "[default]": '400 12px/16px "Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },
    code: {
      "[default]": "400 14px/undefined Menlo",
      xsmall: "400 12px/undefined Menlo",
      small: "400 14px/undefined Menlo",
      medium: "400 16px/undefined Menlo",
    },
    display: {
      "[default]": '400 84px/undefined "Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      medium: '400 72px/78px "Segoe UI VSS (Regular)", "Segoe UI", -apple-system, "system-ui", Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },
  },
};
